export default [
  {
    header: 'Consultation',
  },
  {
    title: 'Recherche',
    route: 'vehicules-search',
    icon: 'SearchIcon',
    resource: 'Vehicule',
    action: 'read',
  },
  {
    title: 'Vehicules en stock',
    route: 'vehicules-stock',
    icon: 'DatabaseIcon',
    resource: 'Vehicule',
    action: 'read',
  },
  {
    title: 'Vehicules en attente destockage',
    route: 'vehicules-attente-destockage',
    icon: 'PauseIcon',
    resource: 'Vehicule',
    action: 'read',
  },
  {
    title: 'Vehicules sortis',
    route: 'vehicules-sortis',
    icon: 'SkipForwardIcon',
    resource: 'Vehicule',
    action: 'read',
  },
  {
    title: 'Déstockage',
    route: 'destockage-list',
    icon: 'FastForwardIcon',
    resource: 'Destockage',
    action: 'read',
  },
  {
    title: 'Statistiques',
    route: 'statistiques-list',
    icon: 'TrendingUpIcon',
    resource: 'Statistique',
    action: 'read',
  },
]
