export default [
  {
    header: 'Admin',
  },
  {
    title: 'Clients',
    route: 'clients-list',
    icon: 'UserIcon',
    resource: 'Client',
    action: 'read',
  },
  {
    title: 'Sites',
    route: 'sites-list',
    icon: 'MapPinIcon',
    resource: 'Site',
    action: 'read',
  },
  {
    title: 'Réceptionnaires',
    route: 'receptionnaires-list',
    icon: 'UserCheckIcon',
    resource: 'Receptionnaire',
    action: 'read',
  },
  {
    title: 'Modèles véhicules',
    route: 'marques-list',
    icon: 'TruckIcon',
    resource: 'Modele',
    action: 'read',
  },
  {
    title: 'Utilisateurs',
    route: 'users-list',
    icon: 'UsersIcon',
    resource: 'Utilisateur',
    action: 'read',
  },
]
