<template>
  <layout-vertical>

    <router-view />

    <!-- Using navbar scoped slot -->
    <div
      slot="navbar"
      slot-scope="slotProps"
      class="navbar-container d-flex content align-items-center"
    >
      <ul class="nav navbar-nav d-xl-none">
        <li class="nav-item">
          <b-link
            class="nav-link"
            @click="slotProps.toggleVerticalMenuActive"
          >
            <feather-icon
              icon="MenuIcon"
              size="21"
            />
          </b-link>
        </li>
      </ul>
      <b-navbar-nav
        class="nav align-items-center ml-auto"
      >
        <div>
          <b-row class="mr-2">

            <span
              class="large-screen"
            >Bienvenue: </span>
            <span class="font-weight-bolder role">
              {{ $store.state.appAuth.user.data.displayName }}
            </span>
            <search-bar v-if="showSearch()" />
          </b-row>
        </div>

        <b-button
          variant="danger"
          class="logout-button"
          @click="logout"
        >
          {{ $t('Logout') }}
        </b-button>
      </b-navbar-nav>

    </div>

    <app-customizer
      v-if="showCustomizer"
      slot="customizer"
    />

  </layout-vertical>
</template>

<script>
import router from '@/router'
import LayoutVertical from '@core/layouts/layout-vertical/LayoutVertical.vue'
import AppCustomizer from '@core/layouts/components/app-customizer/AppCustomizer.vue'
import SearchBar from '@/views/SearchBar.vue'
import { $themeConfig } from '@themeConfig'
import {
  BButton, BNavbarNav, BRow, BLink,
} from 'bootstrap-vue'
import firebase from 'firebase'

export default {
  components: {
    AppCustomizer,
    LayoutVertical,
    BNavbarNav,
    BButton,
    BRow,
    BLink,
    SearchBar,
  },
  data() {
    return {
      showCustomizer: $themeConfig.layout.customizer,
    }
  },
  methods: {
    showSearch() {
      return router.currentRoute.name !== 'vehicules-search'
    },
    async logout() {
      await firebase.auth().signOut()

      // Redirect to login page
      this.$router.push({ name: 'auth-login' })
    },
    userRoleName() {
      if ((this.$ability.can('manage', 'all'))) {
        return 'Admin'
      }
      if (!this.$ability.can('manage', 'Vehicule') && !this.$ability.can('read', 'all')) {
        return 'FrontOffice'
      }
      if ((this.$ability.can('read', 'all')) && (this.$ability.can('manage', 'Vehicule'))) {
        return 'BackOffice'
      }

      return 'Initial User'
    },
  },
}
</script>
<style lang="scss" scoped>
.role {
  margin-left: 5px
}

.welcome-text {
  margin-left: 20px;
  max-width: 30%;
  font-weight: bold;
  font-size: 0.5em
}
@media screen and (max-width: 768px) {
  .large-screen {
    display: none;
  }
  .logout-button{
    width: 50%;
  }
}

</style>
